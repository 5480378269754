import axios from "axios";
import config from "../../config";
import localStorageService from "./localStorageService";
export const BaseURL = config.api_url;

class JwtAuthService {
  user = {
    userId: "1",
    role: "ADMIN",
    displayName: "",
    email: "watsonjoyce@gmail.com",
    photoURL: "/assets/images/face-7.jpg",
    age: 25,
    token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
  };

  loginWithEmailAndPassword = (email, password) => {
    return axios
      .post(`${BaseURL}auth/login`, { email, password })
      .then((res) => {
        if (res.data.status === "success") {
          this.setSession(res.data.Authorization);
          this.setUser(res.data.user);
        }
        return res.data;
      })
      .catch((err) => {
        console.log({ err });
        try {
          return {
            status: err.response.data.status,
            message: err.response.data.message,
          };
        } catch (err) {
          return {
            status: "fail",
            message: "Impossible de vous connecter pour le moment",
          };
        }
      });
  };

  // loginWithToken = () => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve(this.user);
  //     }, 100);
  //   }).then(data => {
  //     this.setSession(data.token);
  //     this.setUser(data);
  //     return data;
  //   });
  // };

  updateProfileService = (
    firstname,
    lastname,
    postal_code,
    phonenumber,
    address,
    city
  ) => {
    return axios
      .put(`${BaseURL}user/me`, {
        firstname,
        lastname,
        postal_code,
        phonenumber,
        address,
        city,
      })
      .then((res) => {
        console.log(res);

        return { status: res.data.status, message: res.data.message };
      })
      .catch((err) => {
        console.log({ err });
        try {
          return {
            status: err.response.status,
            message: err.response.data.message,
          };
        } catch (err) {
          return { status: "failed", message: "Erreur de connexion" };
        }
      });
  };

  updatePassword = (current_password, new_password) => {
    return axios
      .put(`${BaseURL}user/password`, {
        current_password,
        new_password,
      })
      .then((res) => {
        console.log(res);

        return { status: res.data.status, message: res.data.message };
      })
      .catch((err) => {
        console.log({ err });
        try {
          return {
            status: err.response.status,
            message: err.response.data.message,
          };
        } catch (err) {
          return { status: "failed", message: "Erreur de connexion" };
        }
      });
  };

  logout = () => {
    axios.post(`${BaseURL}auth/logout`).catch((err) => {
      console.log(err);
    });

    this.setSession(null);
    console.log(localStorageService.getItem("jwt_token"));
    this.removeUser();
  };

  setSession = (token) => {
    console.log({ token });
    if (token) {
      localStorageService.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new JwtAuthService();
