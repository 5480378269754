import axios from "axios";
import BaseService from "./BaseService";
import { OptimizationUrl } from "./urls";
export default class Optimization {
  static getSectors() {
    return axios
      .get(OptimizationUrl.SECTORS, { headers: BaseService.getHeadersAuth() })
      .then(async (res) => {
        let sectors = await res.data;
        return sectors;
      })
      .catch((error) => {
        alert(error);
        return error;
      });
  }

  static cleanBodyAttributes = (body) => {
    const result = body;
    if (result.paid_reason === null) {
      result.paid_reason = undefined;
    }
    if (result.avantages_origame === null) {
      result.avantages_origame = undefined;
    }
    if (result.detail1 === null) {
      result.detail1 = undefined;
    }
    if (result.detail2 === null) {
      result.detail2 = undefined;
    }
    if (result.conditions_engagement === null) {
      result.conditions_engagement = undefined;
    }
    return result;
  };

  static getFormulesAndSubscriptions(secteur_id) {
    return axios
      .get(OptimizationUrl.FORMULES_AND_SUBSCRIPTIONS(secteur_id), {
        headers: BaseService.getHeadersAuth(),
      })
      .then(async (res) => {
        let sector_data = await res.data;
        let sector_details = { ...sector_data[0], ...sector_data[1] };

        return sector_details;
      })
      .catch((error) => {
        alert(error);
        return error;
      });
  }

  static updateFormule(subscription_id, body) {
    return axios
      .put(
        OptimizationUrl.MANAGE_FORMULE_SUBSCRIPTIONS(subscription_id),
        Optimization.cleanBodyAttributes(body),
        { headers: BaseService.getHeadersAuth() }
      )
      .then(async (res) => {
        return await res.data;
      })
      .catch((error) => error);
  }

  static toggleSubscriptions(subscription_id) {
    return axios
      .delete(
        OptimizationUrl.TOGGLE_SUBSCRIPTIONS_CAN_OPTIMIZE(subscription_id),
        {},
        { headers: BaseService.getHeadersAuth() }
      )
      .then(async (res) => {
        return await res.data;
      })
      .catch((error) => error);
  }

  static addFormule(subscription_id, body) {
    return axios
      .post(
        OptimizationUrl.MANAGE_FORMULE_SUBSCRIPTIONS(subscription_id),
        body,
        { headers: BaseService.getHeadersAuth() }
      )
      .then(async (res) => {
        return await res.data;
      })
      .catch(async (error) => error);
  }

  static deleteFormule(subscription_id, formule_name) {
    return axios
      .delete(
        OptimizationUrl.MANAGE_FORMULE_SUBSCRIPTIONS(subscription_id) +
          "?formule_id=" +
          formule_name,
        {},
        { headers: BaseService.getHeadersAuth() }
      )
      .then(async (res) => {
        return await res.data;
      })
      .catch((error) => error);
  }

  static extratLogs() {
    return axios
      .get(
        OptimizationUrl.EXTRACT_LOGS,
        {},
        { headers: BaseService.getHeadersAuth() }
      )
      .then(async (res) => {
        return await res.data;
      })
      .catch((error) => error);
  }

  static UploadImage(data) {
    return BaseService.postFileRequest(
      OptimizationUrl.UPLOAD_IMAGE,
      data,
      true
    );
  }
}
