import {
  GET_ALL_OPTIMISABLE_SECTEUR,
  INIT_LOAD_OPTIMISABLE_SECTEUR,
  GET_OPTIMISABLE_SECTEUR_BY_ID,
  UPDATE_FORMULE_IN_LIST,
  DELETE_FORMULE_IN_LIST,
  TOGGLE_SUBSCRIPTIONS,
} from "../actions/OptimisationActions";

const initialState = {
  secteurs: [],
  secteurs_details: {},
  isLoading: false,
};

const OptimisationReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_OPTIMISABLE_SECTEUR: {
      return {
        ...state,
        secteurs: action.payload,
      };
    }
    case TOGGLE_SUBSCRIPTIONS: {
      return {
        ...state,
        secteurs_details: {
          ...state.secteurs_details,
        },
      };
    }

    case GET_OPTIMISABLE_SECTEUR_BY_ID: {
      return {
        ...state,
        secteurs_details: {
          ...state.secteurs_details,
          [action.payload.sector.id]: action.payload,
        },
      };
    }
    case UPDATE_FORMULE_IN_LIST: {
      return {
        ...state,
        secteurs_details: {
          ...state.secteurs_details,
        },
      };
    }
    case DELETE_FORMULE_IN_LIST: {
      return {
        ...state,
        secteurs_details: {
          ...state.secteurs_details,
        },
      };
    }
    case INIT_LOAD_OPTIMISABLE_SECTEUR: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default OptimisationReducer;
