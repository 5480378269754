import {SET_NOTIFICATION} from "../actions/NotificationAction";

export const initialState = {
    loading : true,
    data : null,
    errorLoading : false,
    attempt : 0
};

const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION: {
            return {
                ...state,
                ...action.payload};
        }
        default:
            return state;
    }
};
export default NotificationReducer;