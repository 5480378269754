import localStorageService from "../../services/localStorageService";
import jwtAuthService from "../../services/jwtAuthService";
import {authConstants} from '../../constants'
import {LOGIN_SUCCESS, LOGIN_ERROR} from "../actions/LoginActions";
import {
    USER_LOGGED_OUT, UPDATE_PROFILE,
    _USER_DATA, UPDATE_PHOTO, UPDATE_PASSWORD, ERROR_PASSWORD, ERROR_PHOTO
} from "../actions/UserActions";

if (localStorageService.getItem("jwt_token") !== "") {
    jwtAuthService.setSession(localStorageService.getItem("jwt_token"))
}

const initialState = {
    success: localStorageService.getItem("jwt_token") !== "",
    failed: false,
    s_failed: false,
    t_failed: false,
    failed_message: "",
    failed_timestamp: new Date().getTime(),
    data: localStorageService.getItem("jwt_token") !== "" ? localStorageService.getItem("auth_user") : {},
};

const LoginReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            return {
                ...state,
                success: true,

                data: action.data
            }
        }
        case LOGIN_ERROR: {
            return {
                failed: true,
                failed_timestamp: new Date().getTime(),
                failed_message: action.data.message,

            };
        }
        case USER_LOGGED_OUT: {
            return {
                failed: false,
                failed_timestamp: new Date().getTime(),
                success: false,
                data: {}
            };
        }

        case UPDATE_PROFILE: {
            let localData = localStorageService.getItem("auth_user")
            localData = {
                ...localData,
                ...action.data
            }
            console.log(localData)
            localStorageService.setItem("auth_user", localData)
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                },
                failed: false,
                failed_timestamp: new Date().getTime(),
                failed_message: action.message
            };
        }
        case UPDATE_PASSWORD: {
            return {
                ...state,
                s_failed: false,
                failed_timestamp: new Date().getTime(),
                failed_message: action.message
            };
        }
        case ERROR_PASSWORD: {
            return {
                ...state,
                s_failed: true,
                failed_timestamp: new Date().getTime(),
                failed_message: action.data.message
            };
        }
        case _USER_DATA: {
            return {
                ...state,
                failed: true,
                failed_timestamp: new Date().getTime(),
                failed_message: action.data.message
            };
        }
        case UPDATE_PHOTO: {
            let localData = localStorageService.getItem("auth_user")
            localData = {
                ...localData,
                ...action.data
            }
            console.log(localData)
            localStorageService.setItem("auth_user", localData)
            return {
                ...state,
                failed_timestamp: new Date().getTime(),
                failed_message: action.data.message,
                data: {
                    ...state.data,
                    avatar_url: action.data.image_url
                }
            };
        }
        case ERROR_PHOTO: {
            return {
                ...state,
                t_failed: true,
                failed_timestamp: new Date().getTime(),
                failed_message: action.data.message
            };
        }
        default: {
            return state;
        }
    }
};

export default LoginReducer;
