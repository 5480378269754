import history from "../../../history.js";
import jwtAuthService from "../../services/jwtAuthService";
import { getAllUser, updatePhotoService } from "../../services/CrudService.js";
import {adminUsersServices} from "../../services";
import {adminUsersConstants} from '../../constants'
import { alertActions } from './'

export const SET_USER_DATA = "USER_SET_DATA";
export const _USER_DATA = "_USER_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const GET_USERS = "USERS_LIST";
export const UPDATE_PHOTO = "UPDATE_PHOTO";
//export const _ERROR = "UPDATE_ERROR";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const ERROR_PASSWORD = "ERROR_PASSWORD";
export const ERROR_PHOTO = "ERROR_PHOTO";

export const adminUsersActions = {
  setUserData,
  getUsers,
  
}

export function setUserData(users) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: users
    });
  };
}

// function setUserData(user) {
//   return dispatch => {
//     dispatch(request(user));

//     adminUsersServices.updateUser(user)
//         .then(
//             user => { 
//                 dispatch(success(user));
//                 dispatch(alertActions.success('user successfully updated'));
//             },
//             error => {
//                 dispatch(failure(error.toString()));
//                 dispatch(alertActions.error(error.toString()));
//             }
//         );
// };

// function request(user) { return { type: adminUsersConstants.UPDATE_REQUEST, user } }
// function success(user) { return { type: adminUsersConstants.UPDATE_SUCCESS, user } }
// function failure(error) { return { type: adminUsersConstants.UPDATE_FAILURE, error } }

// }

export function updatePhoto(formData){
  console.log("rich!")
  return dispatch => {
    updatePhotoService(formData)
    .then(res =>{
      console.log(res )
      if(res !== "failed!"){
        return dispatch({
          type: UPDATE_PHOTO,
          data: {
            message: res.message,
            image_url:res.image_url
          }
        });
      }else{
        return dispatch({
          type: ERROR_PHOTO,
          data: {
            message: res.message
          }
        });
      }
      
    })
    
  };
}

export function updatePassword({current_password, new_password}){
  
  return dispatch =>{
    jwtAuthService
    .updatePassword(current_password, new_password)
    .then(res => {

      console.log(res )
      
      if(res.status === "success"){
        return dispatch({
          type: UPDATE_PASSWORD,
          message:res.message 
        });
      }else {
        
        return dispatch({
          type: ERROR_PASSWORD,
          data: {
           message: res.message
          }
      })
      
    } 
    })
  }
}

export function updateProfile({firstname, lastname, postal_code, phonenumber,address, city}){
  
  return dispatch => {
    jwtAuthService
    .updateProfileService(firstname, lastname, postal_code, phonenumber,address, city)
    .then(res =>{
      console.log(res )
      
      if(res.status === "success"){
        return dispatch({
          type: UPDATE_PROFILE,
          data: {
            "firstname":firstname, 
            "lastname": lastname,
            "postal_code": postal_code, 
            "phonenumber":phonenumber,
            "address":address,
            "city":city
          },
         message:res.message 
        });
      }else {
        return dispatch({
          type: _USER_DATA,
          data: {
           message: res.message
          }
      })
      
    }
    
  })
}
}
export function logoutUser() {
  return dispatch => {
    jwtAuthService.logout();

    history.push({
      pathname: "/session/signin"
    });

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}

function getUsers(params) {
  const {append = false} = params;
  return dispatch => {
    dispatch(request());

    adminUsersServices.getAllUser(params)
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      )

  };

  function request() { return { type: adminUsersConstants.GETALL_REQUEST } }
  function success(users) { return { type: adminUsersConstants.GETALL_SUCCESS, users, append} }
  function failure(error) { return { type: adminUsersConstants.GETALL_FAILURE, error } }
}


