import config from "../../../config";
export const apiUrl = config.api_url;
export const OptimizationUrl = {
    SECTORS: `${apiUrl}optimization/secteurs`,
    FORMULES_AND_SUBSCRIPTIONS: (secteur_id) => `${apiUrl}optimization/subscriptions/formules/${secteur_id}`,
    MANAGE_FORMULE_SUBSCRIPTIONS: (subscription_id) => `${apiUrl}optimization/formules/${subscription_id}`,
    TOGGLE_SUBSCRIPTIONS_CAN_OPTIMIZE: (subscription_id) => `${apiUrl}optimization/subscriptions/${subscription_id}`,
    UPLOAD_IMAGE: (data) => `${apiUrl}optimization/upload_file`,
    EXTRACT_LOGS: `${apiUrl}administration/log/extract`,
};

export const UserUrl = {
    GET_NOTIFICATION: `${apiUrl}notification`,
    DELETE_USER: public_id => `${apiUrl}administration/users/${public_id}`,
    ADD_ACCOUNT: () => `${apiUrl}administration/account/add_new_admin`,
    EXTRACT_ALL: () => `${apiUrl}administration/user/extract`,
    DISABLE: public_id => `${apiUrl}administration/users/${public_id}`,
};

export const AdviceUrl = {
    GET: `${apiUrl}administration/advice`,
    POST: `${apiUrl}administration/advice`,
};

export const SubscriptionUrl = {
    GET_GLOBAL_STAT : `${apiUrl}statistics/globals`,
    POST_SUB_FIELD : `${apiUrl}subscription/sup_fields`,
    DELETE_SUGGETIONS : id =>  `${apiUrl}administration/subscription-offer/delete/${id}`,
    GET_OFFER: (per_page, keyword) => keyword.length > 0 ?
        `${apiUrl}administration/subscription/offer?page=1&per_page=${per_page}&order=asc&keyword=${keyword}` :
        `${apiUrl}administration/subscription/offer?page=1&per_page=${per_page}&order=asc`,
    GET_STATISTICS: (per_page, keyword, start_date, end_date) => keyword.length > 0 ?
        `${apiUrl}statistics/subscriptions?page=1&per_page=${per_page}&order=asc&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}` :
        `${apiUrl}statistics/subscriptions?page=1&per_page=${per_page}&order=asc&start_date=${start_date}&end_date=${end_date}`,
};

export const SectorUrl = {
    ALL_SECTORS: per_page => `${apiUrl}administration/secteurs?per_page=${per_page}`,
    ADD_SECTOR: `${apiUrl}administration/secteurs`,
    DEL_SECTOR: secteur_id => `${apiUrl}administration/secteurs/${secteur_id}`,
    UPDATE_SECTOR: `${apiUrl}administration/secteurs`,
    GET_ABONNEMENT_BY_SECTOR: (secteur_id, per_page) =>`${apiUrl}administration/subscriptions?per_page=${per_page}&secteur_id=${secteur_id}`,
    UPDATE_ABONNEMENT: `${apiUrl}administration/subscriptions`,
    ADD_ABONNEMENT: `${apiUrl}administration/subscriptions`,
    DEL_ABONNEMENT: subscription_id =>`${apiUrl}administration/subscriptions/${subscription_id }`,
    UPDATE_LOGO: (subscription_id, image) =>`${apiUrl}administration/subscriptions/${subscription_id}/logo`,
};