import {
  SET_USER_DATA,
  USER_LOGGED_OUT,
  GET_USERS,
  UPDATE_PHOTO
} from "../actions/UserActions";

import {adminUsersConstants ,authConstants} from '../../constants'
const initialState = {
  success: false,
  current_user:{},
  data: {
    loading: true,
    data: [],
    total: 0,
    pages: 0,
    current_page: 0,
    per_page: 20
  }
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case adminUsersConstants.UPDATE_SUCCESS: {
      return {
        ...state,
        current_user:action.data
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        data : {
          ...state.data,
          data : action.data
        }
      };
    }
    case adminUsersConstants.GETALL_SUCCESS: {
      return {
        ...state,
        success: true, 
        data: {
          loading: false,
          ...action.users,
          data: !action.append ? action.users.data : [...state.data.data, ...action.users.data]
        }
      };
    }

    case UPDATE_PHOTO: {
      
      return {
        ...state,
        success: true, 
        
      };
    }
    // case REMOVE_USER_DATA: {
    case adminUsersConstants.DELETE_SUCCESS: {
      return {
        ...state,
        success: false,
        data: undefined
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
