import BaseService from './BaseService'
import {SubscriptionUrl} from "./urls";

class DashboardService {
    static getSuggestion = (per_page, keyword) => BaseService.getRequest(SubscriptionUrl.GET_OFFER(per_page, keyword), true);
    static deleteSuggestion = (id) => BaseService.deleteRequest(SubscriptionUrl.DELETE_SUGGETIONS(id), {}, true);
    static getStatistics = (per_page, keyword, startDate, endDate) => BaseService.getRequest(SubscriptionUrl.GET_STATISTICS(per_page, keyword, startDate, endDate), true);
    static getGlobalStatistics = () => BaseService.getRequest(SubscriptionUrl.GET_GLOBAL_STAT, true);
    static postSubFieldDialog = data => BaseService.putRequest(SubscriptionUrl.POST_SUB_FIELD, data, true);
}

DashboardService.publicName = 'DashboardService';
export default DashboardService;
