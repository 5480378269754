import Mock from "../mock";
import shortId from "shortid";

// board => list => items

const ScrumBoardDB = {
  board: [
    {
      id: 1,
      title: "Egret React",
      list: [
        {
          id: shortId.generate(),
          title: "Egret Development",
          items: [
            {
              id: shortId.generate(),
              title: "Drag and Drop",
              members: [1, 2, 3, 4], //uid
              labels: [2, 3, 4],
              description: "dkfjdsjfkdsfskfs",
              attachments: "file link",
              comments: [
                {
                  id: shortId.generate(),
                  uid: 1,
                  text: "kdjfdskfsdfsdfsdfs",
                  time: Date.now()
                }
              ]
            }
          ]
        }
      ],
      labels: [
        {
          id: 1,
          title: "Active"
        },
        {
          id: 2,
          title: "Complete"
        }
      ],
      members: [
        {
          uid: 1,
          name: "John Doe",
          avatar: "assets/images/face-1.jpg"
        }
      ]
    }
  ]
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Moves an item from one list to another list.
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getBoardWithMemberDetails = board => {
  const members = board.members.map(id => {
    return ScrumBoardDB.members.find(member => member.id === id);
  });
  return { ...board, members };
};

Mock.onGet("/api/scrum-board/all-board").reply(config => {
  const response = ScrumBoardDB.board;
  return [200, response];
});

Mock.onGet("/api/scrum-board").reply(config => {
  const id = config.data;

  const board = ScrumBoardDB.boardList.find(board => board.id === id);

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/add-member").reply(config => {
  let { boardId, memberId } = JSON.parse(config.data);
  const board = ScrumBoardDB.boardList.find(board => board.id === boardId);

  board.members.push(memberId);
  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/delete-member").reply(config => {
  let { boardId, memberId } = JSON.parse(config.data);

  const board = ScrumBoardDB.boardList.find(board => board.id === boardId);
  board.members.splice(board.members.indexOf(memberId), 1);

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/add-column").reply(config => {
  let { boardId, listTitle } = JSON.parse(config.data);

  const board = ScrumBoardDB.boardList.find(board => board.id === boardId);
  board.list.push({
    id: shortId.generate(),
    title: listTitle,
    cardList: []
  });

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/rename-column").reply(config => {
  let { boardId, listTitle, listId } = JSON.parse(config.data);

  const board = ScrumBoardDB.boardList.find(board => board.id === boardId);
  board.list.map(column => {
    if (column.id === listId) {
      column.title = listTitle;
    }
    return column;
  });

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/delete-column").reply(config => {
  let { boardId, listId } = JSON.parse(config.data);

  const board = ScrumBoardDB.boardList.find(board => board.id === boardId);
  let deleteIndex = null;

  board.list.map((column, index) => {
    if (column.id === listId) deleteIndex = index;
  });

  board.list.splice(deleteIndex, 1);

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/add-card").reply(config => {
  let { boardId, cardTitle, listId } = JSON.parse(config.data);

  let board = ScrumBoardDB.boardList.find(board => board.id === boardId);
  let column = board.list.find(column => column.id === listId);

  column.cardList.push({
    id: shortId.generate(),
    title: cardTitle,
    members: [],
    labels: [],
    comments: [],
    attachments: []
  });

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/update-card").reply(config => {
  let { boardId, listId, card } = JSON.parse(config.data);

  let boardList = ScrumBoardDB.boardList.map(board => {
    if (board.id === boardId) {
      return {
        ...board,
        list: board.list.map(column => {
          if (column.id === listId) {
            return {
              ...column,
              cardList: column.cardList.map(item => {
                if (item.id === card.id) {
                  return { ...card };
                }
                return { ...item };
              })
            };
          }
          return column;
        })
      };
    }
    return board;
  });

  ScrumBoardDB.boardList = [...boardList];

  let board = boardList.find(item => item.id === boardId);

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/reorder-list").reply(config => {
  let { boardId, startIndex, endIndex } = JSON.parse(config.data);

  ScrumBoardDB.boardList = ScrumBoardDB.boardList.map(board => {
    if (board.id === boardId) {
      return {
        ...board,
        list: reorder(board.list, startIndex, endIndex)
      };
    }
    return board;
  });

  let board = ScrumBoardDB.boardList.find(item => item.id === boardId);
  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/reorder-card").reply(config => {
  let { boardId, listId, startIndex, endIndex } = JSON.parse(config.data);

  ScrumBoardDB.boardList = ScrumBoardDB.boardList.map(board => {
    if (board.id === boardId) {
      return {
        ...board,
        list: board.list.map(list => {
          if (list.id === listId) {
            return {
              ...list,
              cardList: reorder(list.cardList, startIndex, endIndex)
            };
          }
          return list;
        })
      };
    }
    return board;
  });

  let board = ScrumBoardDB.boardList.find(board => board.id === boardId);
  const response = getBoardWithMemberDetails(board);

  return [200, response];
});

Mock.onPost("/api/scrum-board/move-card").reply(config => {
  let {
    boardId,
    sourcelistId,
    destinationlistId,
    source,
    destination
  } = JSON.parse(config.data);
  let board = ScrumBoardDB.boardList.find(board => board.id === boardId);

  let [movedItem] = board.list
    .find(list => list.id === sourcelistId)
    .cardList.splice(source.index, 1);

  board.list
    .find(list => list.id === destinationlistId)
    .cardList.splice(destination.index, 0, movedItem);

  const response = getBoardWithMemberDetails(board);

  return [200, response];
});
