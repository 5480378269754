import {SectorService} from "../../services/api";

export const GET_ALL_SECTORS = "GET_ALL_SECTORS";
export const GET_ALL_DETAILS = "GET_ALL_DETAILS";
export const INIT_LOAD_SECTEUR = "INIT_LOAD_SECTEUR";
export const GET_ABONNEMENT_BY_SECTOR = "GET_ABONNEMENT_BY_SECTOR";

export const getAllSectors = (per_page) => {
    return dispatch => {
        dispatch({type: INIT_LOAD_SECTEUR, payload: true});
        SectorService.getAllSectors(per_page)
           .then(secteurs =>{
               dispatch({
                   type: GET_ALL_SECTORS,
                   payload: secteurs.data
               });
               dispatch({type: INIT_LOAD_SECTEUR, payload: false});
           }, error => {alert(error.toString())})

    };
};

export const getAllSelected = (data) =>{
  return dispatch =>{
     dispatch({
        type: GET_ALL_SECTORS,
        payload: data
     });
  }
};

export const getAllAbonnement = data =>{
   return dispatch =>{
      dispatch({
         type: GET_ABONNEMENT_BY_SECTOR,
         payload: data
      })
   }
};


export const getAbonnementsBySector = (secteur_id, per_page=40) =>{
   return dispatch =>{
      dispatch({type: INIT_LOAD_SECTEUR, payload: true});
      SectorService.getAbonnementBySector(secteur_id, per_page)
         .then(response =>{
            dispatch({
               type: GET_ABONNEMENT_BY_SECTOR,
               payload: response.data
            });
            dispatch({type: INIT_LOAD_SECTEUR, payload: false})
         }, error => {alert(error.toString())})
   }
};


