import axios from 'axios';
import BaseService from './BaseService'
import {SectorUrl} from "./urls";

export default class Sector {
   static getAllSectors(per_page = 40){
      return axios.get(SectorUrl.ALL_SECTORS(per_page), {headers: BaseService.getHeadersAuth()})
         .then(async res =>{
            let sectors = await res.data;
            return sectors;
         })
         .catch(error =>{
            alert(error);
            return error;
         })
   };

   static addSector(data){
      return BaseService.postRequest(SectorUrl.ADD_SECTOR, data, true);
   };

   static updateSector(data){
      return BaseService.putRequest(SectorUrl.UPDATE_SECTOR, data, true);
   };

   static deleteSector(secteur_id){
      return BaseService.deleteRequest(SectorUrl.DEL_SECTOR(secteur_id), {}, true)
   };


   static getAbonnementBySector(secteur_id, per_page=40){
      return axios.get(SectorUrl.GET_ABONNEMENT_BY_SECTOR(secteur_id, per_page), {headers: BaseService.getHeadersAuth()})
         .then(async res =>{
            let abonnements = await res.data;
            return abonnements;
         })
         .catch(error =>{
            alert(error);
            return error;
         })
   };

   static updateAbonnement(data){
      return BaseService.putRequest(SectorUrl.UPDATE_ABONNEMENT, data, true)
   };

   static delAbonnement(subscription_id){
      return BaseService.deleteRequest(SectorUrl.DEL_ABONNEMENT(subscription_id), {}, true)
   };

   static addAbonnement(data){
      return BaseService.postRequest(SectorUrl.ADD_ABONNEMENT, data, true)
   };

   static updateLogo(id, data){
      return BaseService.putFileRequest(SectorUrl.UPDATE_LOGO(id), data, true);
   }
}