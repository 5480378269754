import React from "react";

const EgretSidenavContainer = ({ children }) => {
  return (
    <div className="egret-sidenav-container position-relative h-100">
      {children}
    </div>
  );
};

export default EgretSidenavContainer;
