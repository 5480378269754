import "../fake-db";
import "../styles/_app.scss";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "../history.js";
import { SnackbarProvider } from 'notistack';
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import Auth from "./auth/Auth";
import EgretLayout from "./EgretLayout/EgretLayout";
import AuthGuard from "./auth/AuthGuard";
import { makeStyles } from '@material-ui/core/styles';

const stylesNotif = makeStyles({
  success: { backgroundColor: '#43a047', color: "white !important" },
  error: { backgroundColor: '#d32f2f', color: "white !important" },
  warning: { backgroundColor: '#ffa000', color: "white !important" },
  info: { backgroundColor: '#2979ff', color: "white !important" },
});


const App = () => {
  const classes = stylesNotif();

  return (
    <AppContext.Provider value={{ routes }}>
      <SnackbarProvider 
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        maxSnack={3}     
        domRoot={document.getElementById("react-notification")}  
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Provider store={Store}>
          <EgretTheme>
            <Auth>
              <Router history={history}>
                <AuthGuard>
                  <EgretLayout />
                </AuthGuard>
              </Router>
            </Auth>
          </EgretTheme>
        </Provider>
      </SnackbarProvider>
    </AppContext.Provider>
  );
};

export default App;
