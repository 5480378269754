import {EgretLoadable} from "../../../egret";

const Board = EgretLoadable({
   loader: () => import("./Board")
});

const ticketsRoutes = [
   {
      path: "/tickets",
      component: Board
   },

];

export default ticketsRoutes;