import { combineReducers } from "redux";
import LoginReducer from './LoginReducer';
import UserReducer from './UserReducer';
import TicketReducer from './TicketReducer';
import LayoutReducer from './LayoutReducer';
import ScrumBoardReducer from './ScrumBoardReducer'
import SecteurReducer from './SecteurReducer'
import OptimisationReducer from './OptimisationReducer'
import NotificationReducer from './NotificationReducer'

const RootReducer = combineReducers({
  login: LoginReducer,
  users: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  tickets: TicketReducer,
  secteurs: SecteurReducer,
  optimisation: OptimisationReducer,
  notifications : NotificationReducer

});

export default RootReducer;
