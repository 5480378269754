import {GET_ABONNEMENT_BY_SECTOR, GET_ALL_SECTORS, INIT_LOAD_SECTEUR} from "../actions/SecteursActions";

const initialState = {
    secteurs: [],
    isLoading: false,
    abonnements: [],
};

const SecteursReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_SECTORS: {
            let secteurs = action.payload;
            secteurs = secteurs.sort((a, b) => a.id - b.id);
            return {
                ...state,
                secteurs: secteurs,
            };
        }

        case GET_ABONNEMENT_BY_SECTOR: {
            let abonnements = action.payload;
            return {
                ...state,
                abonnements: abonnements
            }
        }

        case INIT_LOAD_SECTEUR: {
            return {
                ...state,
                isLoading: action.payload
            }
        }

        default: {
            return state;
        }
    }
};

export default SecteursReducer;
