import { EgretLoadable } from "../../../egret";

const AppOptimisationBoard = EgretLoadable({
  loader: () => import("./AppOptimisationBoard")
});

const Board = EgretLoadable({
  loader: () => import("./Board")
});

const OptimizationRoutes = [
  {
    path: "/optimisations/:id",
    component: Board
  },
  {
    path: "/optimisations",
    component: AppOptimisationBoard
  }
];

export default OptimizationRoutes;
