import BaseService from './BaseService'
import {UserUrl} from "./urls";

class DashboardService {
    static deleteUser = (public_id) => BaseService.deleteRequest(UserUrl.DELETE_USER(public_id), {}, true);
    static disableAccount = (public_id) => BaseService.putRequest(UserUrl.DISABLE(public_id), {}, true);
    static addAccount = (data) => BaseService.postRequest(UserUrl.ADD_ACCOUNT(), data, true);
    static extractAll = () => BaseService.getRequest(UserUrl.EXTRACT_ALL(), true);
}

DashboardService.publicName = 'DashboardService';
export default DashboardService;
