import * as actionTypes from '../actions';

const initialState = {
    "entete": [
        {
            "id": 1,
            "name": "EN ATTENTE",
            "icon": "⏱",
            "value": "PENDING",
            "list": [],
        },
        {
            "id": 2,
            "value": "BLOCKED",
            "name": "BLOQUE",
            "icon": "🚫",
            "list": [],
        },
        {
            "id": 3,
            "value": "GOING",
            "name": "EN COURS",
            "icon": "⚙",
            "list": [],
        },
        {
            "id": 4,
            "value": "FINISHED",
            "name": "TRAITE",
            "icon": "✅",
            "list": [],
        },
        {
            "id": 5,
            "value": "CANCELED",
            "name": "ANNULE",
            "icon": "⚠",
            "list": [],
        },
    ],
    boardId: 1,
};

const formatData = elem => {
    const data = JSON.parse(elem.data);
    const nature = data.find(i => i.labelle === 'Nature');
    return {
        id: elem.id,
        comments_number: elem.comments_number,
        info_type: elem.info_type,
        is_simply_move: elem.is_simply_move,
        data: [...JSON.parse(elem.data), ...((elem.user_subscription && elem.user_subscription.sup_infos) ?
            elem.user_subscription.sup_infos.map(i => ({...i, labelle : i.name})) : [])],
        user_subscription: elem.user_subscription || {},
        user: elem.user,
        files_number: elem.files_number,
        title: (!elem.user_subscription && !!nature)
            ? 'Souscription'
            : elem.operation === 'bank_transfer'
                ? 'Demande de virement'
                : elem.operation === "optimization"
                    ? "Optimisation"
                    : elem.operation === "termination"
                        ? "Résiliation"
                        : elem.operation === "investigation"
                            ? "Investigation"
                            : "Mise en pause",
        ticket_number: elem.ticket_number ? elem.ticket_number : "20200123000029",
        secteur: elem.operation === 'bank_transfer'
            ? 'Parainage'
            : elem.operation === "investigation"
                ? "investigation"
                : elem.user_subscription
                ? elem.user_subscription.subscription.secteur.name
                : !!nature
                    ? nature.value
                    : "assurance",
    }
};

const TicketReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELEGATE_TO_SIMPLY_MOVE: {
            return {
                ...state,
                entete: state.entete.map((entete, index) => {
                    if (index === 2) {
                        return {
                            ...entete,
                            list: entete.list.map(card => {
                                if (card.id === action.payload) {
                                    return {...card, is_simply_move: true};
                                }
                                return card;
                            })
                        };
                    }
                    return entete;
                })
            };
        }
        case actionTypes.DROP_TICKET_TO_LIST: {
            let subscription_to_drag = state.entete[action.payload.source - 1].list[action.payload.source_index]
            return {
                entete: state.entete.map(entete => {
                    let new_list = [...entete.list]
                    if (entete.id === action.payload.source) {
                        new_list.splice(action.payload.source_index, 1)
                        return {
                            ...entete,
                            list: new_list
                        }
                    } else if (entete.id === action.payload.destination) {
                        new_list.splice(action.payload.destination_index, 0, subscription_to_drag)
                        return {
                            ...entete,
                            list: new_list
                        }
                    }
                    return entete
                })
            }
        }
        case actionTypes.SET_ALL_TICKETS_STATE: {
            const newEntete = [...state.entete].map(i => ({...i, list: []}));

            action.payload.forEach(elem => {
                switch (elem.status) {
                    case "CANCELED": {
                        newEntete[4].list.push(formatData(elem))
                        return;
                    }
                    case "FINISHED": {
                        newEntete[3].list.push(formatData(elem))
                        return;
                    }
                    case "GOING": {
                        newEntete[2].list.push(formatData(elem))
                        return;
                    }
                    case "BLOCKED": {
                        newEntete[1].list.push(formatData(elem));
                        return;
                    }
                    case "PENDING": {
                        newEntete[0].list.push(formatData(elem));
                        return;
                    }
                    default: {
                        return;
                    }
                }
            })

            return {
                entete: newEntete,
            }
        }
        default: {
            return state;
        }
    }
};


export default TicketReducer;
