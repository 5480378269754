import SecureLS from 'secure-ls';

class localStorageService {
   localStorage = new SecureLS({encodingType: 'aes'});
   //ls = window.localStorage

  setItem(key, value) {
    console.log({key, value, "setItem": "setItem"})
    this.localStorage.set(key, value)
    return true
  }

  getItem(key) {
    try {
      return this.localStorage.get(key)
    } catch (e) {
      return null
    }
  }

}

export default new localStorageService();