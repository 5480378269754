export const adminUsersConstants = {

    ADD_REQUEST: 'USERS_ADD_REQUEST',
    ADD_SUCCESS: 'USERS_ADD_SUCCESS',
    ADD_FAILURE: 'USERS_ADD_FAILURE',
    ADD_ACTION : 'ADD_ACTION',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',
    UPDATE_ACTION: 'UPDATE_ACTION',
    
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE'    
};
