import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { adminUsersActions } from "./";
import history from "../../../history.js";
import AuthGuard from "../../auth/AuthGuard";




export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";


export function loginWithEmailAndPassword({ email, password }) {

  return dispatch => {
    jwtAuthService
      .loginWithEmailAndPassword(email, password)
      .then(data => {
        if(data.status === "success"){
          dispatch(adminUsersActions .setUserData(data.user));
          console.log(data.user)
          dispatch({
            type: LOGIN_SUCCESS,
            data: data.user
          });    
          history.push({
            pathname: "/dashboard",
          });                 
      }else{
        return dispatch({
          type: LOGIN_ERROR,
          data: {
           message: data.message 
          }
        });
      
      }
    })        
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return dispatch => {
    FirebaseAuthService.signInWithEmailAndPassword(email, password)
      .then(user => {
        if (user) {
          dispatch(
            adminUsersActions .setUserData({
              userId: "1",
              role: "ADMIN",
              displayName: "Watson Joyce",
              email: "watsonjoyce@gmail.com",
              photoURL: "/assets/images/face-7.jpg",
              age: 25,
              token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
              ...user
            })
          );

          history.push({
            pathname: "/"
          });

          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}
