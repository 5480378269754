export const SET_ALL_TICKETS_STATE = "SET_ALL_TICKETS_STATE";
export const DROP_TICKET_TO_LIST = "DROP_TICKET_TO_LIST";
export const DELEGATE_TO_SIMPLY_MOVE = "DELEGATE_TO_SIMPLY_MOVE";

export const SetTicketsState = (payload) => dispatch =>
   dispatch({
      type: SET_ALL_TICKETS_STATE,
      payload: payload
   });

export const dropTicketToList = (payload) => dispatch =>
   dispatch({
      type: DROP_TICKET_TO_LIST,
      payload: payload
   });

export const delegateToSimplyMove = (payload) => dispatch =>
  dispatch({
    type: DELEGATE_TO_SIMPLY_MOVE,
    payload,
  })