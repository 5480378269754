import React from "react";

const EgretSidenavContent = ({ open, children }) => {
  return (
    <div className={`egret-sidenav-content ${open ? "" : "overflow-auto"}`}>
      {children}
    </div>
  );
};

export default EgretSidenavContent;
