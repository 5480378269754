import moment from "moment";

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition() {
  if (!window) {
    return;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);
  if (!elmID || !elm) {
    return;
  }
  var startY = currentYPosition();
  var stopY = elmYPosition(elm);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "YYYY-MM-DDTHH:mm:ss")
    ) / 1000;

  if (difference < 60) return `${Math.floor(difference)} secondes`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} heures`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} jours`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} mois`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} années`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  );
  var definitions = search.split("&");
  definitions.forEach(function (val, key) {
    var parts = val.split("=", 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(" ");
}

export function getOrigamerList() {
  return [
    {
      id: 1,
      name: "L’écolo",
      color: "green",
      description:
        "<div>- La pollution environnementale est votre préoccupation majeure.<br/>- Vous préférez les offres vertes.</div>",
      image_url: "https://files.dev.origame.fr/",
      advantages:
        "<div>1. Consommer selon ses valeurs.<br/>2. Améliorer son bilan carbone.<br/>3. Préserver notre (seule et unique) planète !<div/>",
      characteristics:
        "<div>- La pollution environnementale est votre préoccupation majeure et celle de notre siècle. #okboomer<br/>- Vous préférez les offres vertes car vous souhaitez faire attention à votre consommation.</div>",
    },
    {
      id: 2,
      name: "Le rationnel",
      color: "purple",
      description:
        "<div>- Vous privilégiez la qualité des services.<br/>- Le plus important c’est de payer le juste prix.</div>",
      image_url: "https://files.dev.origame.fr/",
      advantages:
        "<div>1. Des prix défiant toute concurrence.<br/>2. Des services de qualités, connus de tous.<br/>3. Un rapport qualité/prix imbattable.</div>",
      characteristics:
        "<div>- Premier réflexe : la qualité du service ! Le reste, on verra :) Vous voulez en avoir pour votre argent. Pas besoin de fioritures, tant que ça fonctionne.<br/>- Pas +, pas -. Le plus important c’est de payer le juste prix. Tout travail mérite salaire mais le service doit être à la hauteur.</div>",
    },
    {
      id: 3,
      name: "L’humain",
      color: "primary",
      description:
        "<div>- La condition des employés est votre priorité.<br/>- La redistribution des bénéfices de l’ entreprise est importante pour vous.</div>",
      image_url: "https://files.dev.origame.fr/",
      advantages:
        "<div>1. Consommer selon ses valeurs.<br/>2. Privilégier les entreprises soucieuses de leurs employés.<br/>3. Favoriser un autre modèle de consommation.</div>",
      characteristics:
        "<div>- La condition des employés est votre priorité. Vous prêtez attention aux relations employeur/employés. #bosswatching<br/>- La redistribution des bénéfices de l’entreprise est importante pour vous.</div>",
    },
    {
      id: 4,
      name: "Le made in france",
      color: "secondary",
      description:
        "<div>- De la production à la fiscalité, vous privilégiez les entreprises produisant en France.<br/>- Consommer local est mieux selon vous.</div>",
      image_url: "https://files.dev.origame.fr/",
      advantages:
        "<div>1. Favoriser les entreprises installées sur le territoire français.<br/>2. Avoir un impact sur l'économie française par l'impôt et la consommation.<br/>3. S'assurer des conditions de production.</div>",
      characteristics:
        "<div>- De la production à la fiscalité, vous privilégiez les entreprises produisant en France. Un petit coup de pouce pour les entreprises sur le territoire français, normal !<br/>- Consommer local est important selon vous. Vous n'aimez pas trop les longs trajets d'avions juste pour acheter une brosse à dent...</div>",
    },
    {
      id: 5,
      name: "L’innovant",
      color: "marron",
      description:
        "<div>- Vous êtes un amateur de nouvelles technologies !<br/>- Vous êtes toujours partant pour essayer un nouveau produit.</div>",
      image_url: "https://files.dev.origame.fr/",
      advantages:
        "<div>1. Bénéficier de services/produits nouveaux et inédits.<br/>2. Favoriser les entreprises qui ne se reposent pas sur leur lauriers.<br/>3. Être au top. Juste ça.</div>",
      characteristics:
        "<div>-  Vous êtes un amateur de nouvelles technologies ! Le dernier iPhone ou le bon plan du moment, c'est votre kiff.<br/>- Vous êtes toujours partant pour essayer un nouveau produit. Essayer, ça ne vous fait pas peur. #stevejobs</div>",
    },
    {
      id: 6,
      name: "Le responsable",
      color: "blue",
      description:
        "<div>- Vous privilégiez les entreprises avec un impact social & environnemental positif.<br/>- Vous êtes soucieux de vos données personnelles.</div>",
      image_url: "https://files.dev.origame.fr/",
      advantages:
        "<div>1. Avoir un impact positif sur la planète sans trop dépenser.<br/>2. Protéger vos données personnelles. #bigbrother<br/>3. Trouver le juste équilibre dans votre consommation.</div>",
      characteristics:
        "<div>-  Vous privilégiez les entreprises avec un impact social & environnemental positif. Sans tout changer, vous voulez améliorer votre impact positif.<br/>- Vous êtes soucieux de vos données personnelles. Vous ne voulez plus que l'on utilise vos données sans votre accord et le RGPD est votre ami.</div>",
    },
  ];
}
