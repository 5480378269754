import axios from "axios";
import config from "../../config";
import { UserService } from "./api";

export const BaseURL = config.api_url;

const queryString = require("query-string");
export const adminUsersServices = {
  getAllUser,
  getUserById,
  deleteUser,
  addNewUser,
  updateUser,
};

function getAllUser(params) {
  let param_str = queryString.stringify(params);
  let token = localStorage.getItem("jwt_token");
  let url = `${BaseURL}administration/users`.concat("?", param_str);
  console.log(url);
  return axios
    .get(url)
    .then((res) => {
      console.log(res);
      let users = res.data;
      return users;
    })
    .catch((error) => {
      return error;
    });
}

export const updatePhotoService = (formData) => {
  return axios
    .post(`${BaseURL}user/avatar`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return "failed!";
    });
};
function getUserById(id) {
  return axios.get("/api/user", { data: id });
}

function deleteUser(User) {
  return axios.post(`${BaseURL}administration/users`, User).then((res) => {
    console.log(res);
  });
}
function addNewUser(User) {
  return axios.post("/api/user/add", User);
}
function updateUser(User) {
  return axios.post("/api/user/update", User);
}
