import BaseService from './BaseService'
import {AdviceUrl} from "./urls";

class AdvicesService{
    static getAdvice = () => BaseService.getRequest(AdviceUrl.GET,  true);
    static postAdvice = (form) => BaseService.postRequest(AdviceUrl.POST, form, true);
}

AdvicesService.publicName = 'AdvicesService';
export default AdvicesService;
