import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { adminUsersActions} from "../redux/actions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import firebaseAuthService from "../services/firebase/firebaseAuthService";

class Auth extends Component {
  state = {};
  
  constructor(props) {
    super(props);

    // this.props.setUserData(localStorageService.getItem("auth_user"));
    // this.checkJwtAuth();
    // this.checkFirebaseAuth();
  }

  checkJwtAuth = () => {
    // jwtAuthService.loginWithToken().then(user => {
    //   this.props.setUserData(user);
    // });
  };

  checkFirebaseAuth = () => {
    // firebaseAuthService.checkAuthStatus(user => {
    //   if (user) {
    //     console.log(user.uid);
    //     console.log(user.email);
    //     console.log(user.emailVerified);
    //   } else {
    //     console.log("not logged in");
    //   }
    // });
  };

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
  mapStateToProps,
  { setUserData :adminUsersActions.setUserData }
)(Auth);
