import React from "react";
import {Dialog, Button} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const ConfirmationDialog = ({
                                open,
                                onConfirmDialogClose,
                                text,
                                loading,
                                title = "confirm",
                                onYesClick
                            }) => {
    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={open}
            onClose={onConfirmDialogClose}
        >
            <div className="pt-24 px-20 pb-8">
                <h4 className="capitalize">{title}</h4>
                <p>{text}</p>
                <div className="flex flex-end pt-8">
                    <Box display={'inline'} position={'relative'}>
                        {
                            loading &&
                            <Box style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <CircularProgress size={24}/>
                            </Box>
                        }
                        <Button disabled={loading} onClick={onYesClick} style={{marginRight: 10}} variant="contained" color="primary">
                            Oui
                        </Button>
                    </Box>

                    <Button
                        onClick={onConfirmDialogClose}
                        variant="contained"
                        color="secondary"
                    >
                        Non
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmationDialog;
