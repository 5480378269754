import axios from "axios";
import { OptimizationService } from "../../services/api";

export const GET_ALL_OPTIMISABLE_SECTEUR = "GET_ALL_OPTIMISABLE_SECTEUR";
export const GET_OPTIMISABLE_SECTEUR_BY_ID = "GET_OPTIMISABLE_SECTEUR_BY_ID";
export const INIT_LOAD_OPTIMISABLE_SECTEUR = "INIT_LOAD_OPTIMISABLE_SECTEUR";
export const UPDATE_FORMULE_IN_LIST = "UPDATE_FORMULE_IN_LIST";
export const DELETE_FORMULE_IN_LIST = "DELETE_FORMULE_IN_LIST";
export const TOGGLE_SUBSCRIPTIONS = "TOGGLE_SUBSCRIPTIONS";

export const getOptimisableSecteur = () => {
  return (dispatch) => {
    dispatch({ type: INIT_LOAD_OPTIMISABLE_SECTEUR, payload: true });
    OptimizationService.getSectors().then(
      (secteurs) => {
        dispatch({
          type: GET_ALL_OPTIMISABLE_SECTEUR,
          payload: secteurs,
        });
        dispatch({ type: INIT_LOAD_OPTIMISABLE_SECTEUR, payload: false });
      },
      (error) => {
        alert(error.toString());
      }
    );
  };
};
export const updateFormuleInList = (payload) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_FORMULE_IN_LIST, payload });
    OptimizationService.getFormulesAndSubscriptions(payload.secteur).then(
      (secteur) => {
        dispatch({
          type: GET_OPTIMISABLE_SECTEUR_BY_ID,
          payload: secteur,
        });
        dispatch({ type: INIT_LOAD_OPTIMISABLE_SECTEUR, payload: false });
      },
      (error) => {
        alert(error.toString());
      }
    );
  };
};
export const deleteFormuleInList = (payload) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FORMULE_IN_LIST, payload });
    OptimizationService.getFormulesAndSubscriptions(payload.secteur).then(
      (secteur) => {
        dispatch({
          type: GET_OPTIMISABLE_SECTEUR_BY_ID,
          payload: secteur,
        });
        dispatch({ type: INIT_LOAD_OPTIMISABLE_SECTEUR, payload: false });
      },
      (error) => {
        alert(error.toString());
      }
    );
  };
};

export const toggleSubscriptions = (payload) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_SUBSCRIPTIONS, payload });
    OptimizationService.getFormulesAndSubscriptions(payload.secteur).then(
      (secteur) => {
        dispatch({
          type: GET_OPTIMISABLE_SECTEUR_BY_ID,
          payload: secteur,
        });
        dispatch({ type: INIT_LOAD_OPTIMISABLE_SECTEUR, payload: false });
      },
      (error) => {
        alert(error.toString());
      }
    );
  };
};

export const getSecteurOptimisaitonById = (id) => {
  return (dispatch) => {
    dispatch({ type: INIT_LOAD_OPTIMISABLE_SECTEUR, payload: true });
    OptimizationService.getFormulesAndSubscriptions(id).then(
      (secteur) => {
        dispatch({
          type: GET_OPTIMISABLE_SECTEUR_BY_ID,
          payload: secteur,
        });
        dispatch({ type: INIT_LOAD_OPTIMISABLE_SECTEUR, payload: false });
      },
      (error) => {
        alert(error.toString());
      }
    );
  };
};
