import history from "../../../history.js";
import jwtAuthService from "../../services/jwtAuthService";
import {authConstants} from '../../constants'

export const authActions = {
   logoutUser
}
function logoutUser() {
    return dispatch => {
      jwtAuthService.logout();
  
      history.push({
        pathname: "/session/signin"
      });
  
      dispatch({
        type: authConstants.LOGOUT
      });
    };
  }