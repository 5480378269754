import React, { Component, useState } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  Snackbar, 
  Fade
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";


import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { amber, green } from "@material-ui/core/colors";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    color: "white !important",
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    color: "white !important",
    alignItems: "center"
  }
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    agreement: "",
    loading: false,
    open:false,
    submited:false
  };

   style={
    backgroundColor : 'red',
    color: 'white',

  }
 
  handleClose = () => {
    this.setState({open: false})
  }

  handleChange = event => {
    if(this.state.open){
      this.setState({
         open: false
      })
    }

    if(this.state.loading){
      this.setState({
         loading: false
      })
    }
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
   
  async postData() {
    if(!this.state.loading){
      await  this.props.loginWithEmailAndPassword({ ...this.state }) ; 
       this.setState({
         loading:true
       });
     }
  }

  
   handleFormSubmit =  async () =>{  
     
    await this.postData();
    this.setState({
      submited:true
    });
   
  }

  componentDidMount(){
    if(this.props.login.success){
      this.props.history.push("/dashboard")
    }
  }
     
  componentDidUpdate(nextProps){
    if(nextProps.login.failed_timestamp !== this.props.login.failed_timestamp && this.props.login.failed == true){
      this.setState({open: true, submited: false, loading: false})
    }     
  }

  render() {
    
    let { email, password } = this.state;
    
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center flex-middle h-350" style={{height: 350}}>
                  <img src="/assets/images/logo.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100 bg-light-gray" style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "Ce champ est requis",
                        "Le formt du mail est invalide"
                      ]}
                    />
                    <TextValidator
                      className="mb-16 w-100"
                      label="Mot de passe"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["Ce champ est requis"]}
                    />
                    <div className="flex flex-middle">
                      <div className="wrapper">
                        <Button
                          className="capitalize"
                          variant="contained"
                          disabled={this.state.loading}
                          color="primary"
                          type="submit"
                        >
                          Se connecter
                        </Button>
                        {this.state.loading && <CircularProgress className="buttonProgress" size={24}/>}
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.open}
          autoHideDuration={4000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="error"
            message={this.props.login.failed_message}
          />
      </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
  data: state.login.data
  
});
export default withRouter(
  connect(
    mapStateToProps,
    { loginWithEmailAndPassword }
  )(SignIn)
);
