import { EgretLoadable } from "../../../egret";
import { authRoles } from "../../auth/authRoles";

const LearningManagement = EgretLoadable({
  loader: () => import("./LearningManagement")
});

const UserProfile = EgretLoadable({
  loader: () => import("./UserProfile")
});

const DashboardCommon = EgretLoadable({
  loader: () => import("./CommonComponent")
});

const SubscriptionSuggestion = EgretLoadable({
  loader: () => import("./SuggestionSubscription")
});

const Statistics = EgretLoadable({
  loader: () => import("./Statistics")
});

const dashboardRoutes = [
  {
    path: "/component/common",
    component: DashboardCommon,
    auth: authRoles.guest
  },
  // {
  //   path: "/dashboard/analytics",
  //   component: Analytics,
  //   auth: authRoles.guest
  // },
  {
    path: "/statistique",
    component: Statistics,
    auth: authRoles.guest
  },
  {
    path: "/suggestion-contrat",
    component: SubscriptionSuggestion,
    auth: authRoles.guest
  },
  {
    path: "/dashboard",
    component: LearningManagement
  },
    {
        path: "/user/profile",
        component: UserProfile
    },

];

export default dashboardRoutes;
