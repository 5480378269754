import {EgretLoadable} from "../../../egret";


const AppSecteurs = EgretLoadable({
    loader: () => import("./AppSecteurs")
});

const SecteurDetail = EgretLoadable({
    loader: () => import("./SecteurDetail")
});

const AbonnementDetail = EgretLoadable({
    loader: () => import('./AbonnementDetails')
});


const secteursRoutes = [
    {
        path: "/secteurs",
        component: AppSecteurs,
        exact : true,
    },
    {
      path: "/secteurs/:id",
      component: SecteurDetail
    },
    {
        path: '/abonnement/:id/:abonnement_id',
        component: AbonnementDetail,
        exact: true,
    }
];

export default secteursRoutes;